import Vue from 'vue';
import VueRouter from 'vue-router';
import backup from '@/router/backup';
import migration from '@/router/migration';
import payment from '@/router/payment';
import user from '@/router/user';
import manager from '@/router/manager';
import Assisted from '@/pages/components/assisted';
import AssistedThankYou from '@/pages/components/assisted/thank-you';
import Sugar from '@/pages/components/sugar';
import Suite from '@/pages/components/suite';
import NotFound from '@/pages/components/not-found';
import Help from '@/pages/components/help';
import Policy from '@/pages/components/policy';
import { DEV, PRODUCTS } from '@/store/modules/acl-access/constants';
import { ROUTE_NAME_ASSET_ESTIMATE_LIST, ROUTE_NAME_ESTIMATE_LIST } from '@/modules/estimate/router.js';
import { ROUTE_NAME_LIST } from '@/modules/replicator/router.js';
import {
    set as setReturnUrl,
    remove as removeReturnUrl,
} from '@/assets/util/return-url';

const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject);

    return originalPush.call(this, location).catch((err) => {
        if (VueRouter.isNavigationFailure(err)) return err;

        return Promise.reject(err);
    });
};

Vue.use(VueRouter);

const ROOT_ROUTES = [
    {
        product: PRODUCTS.MIGRATION,
        route:   'migrationList',
    },
    {
        product: PRODUCTS.BACKUP,
        route:   'backupList',
    },
    {
        product: PRODUCTS.ESTIMATE,
        route:   ROUTE_NAME_ESTIMATE_LIST,
    },
    {
        product: PRODUCTS.REPLICATOR,
        route:   ROUTE_NAME_LIST,
    },
    {
        product: PRODUCTS.ASSET_ESTIMATE,
        route:   ROUTE_NAME_ASSET_ESTIMATE_LIST,
    },
];

const getRoutes = ({ store }) => [
    ...backup,
    ...migration,
    ...payment,
    ...user,
    ...manager,
    {
        name:     'root',
        path:     '/',
        redirect: () => {
            const name = ROOT_ROUTES.find(item => store.getters['aclAccess/isGranted'](item.product))?.route ?? 'migrationList';

            return { name };
        },
    },
    {
        name:      'devSandbox',
        path:      '/dev-sandbox',
        component: () => import('@/pages/components/dev-sandbox'),
        meta:      {
            title:  'Dev Sandbox',
            acl:    DEV.SANDBOX,
            layout: 'content',
        },
    },
    {
        name:      'oAuthAuthorize',
        path:      '/oauth/:type/(authorize|callback)/',
        component: () => import('@/components/o-auth/page'),
        meta:      {
            layout: 'content',
        },
    },
    {
        name:      'help',
        path:      '/help',
        component: Help,
        meta:      {
            title:       (route, { i18n }) => i18n.t('page title help'),
            breadcrumbs: (route, { i18n }) => ([
                {
                    label: i18n.t('header breadcrumb help'),
                },
            ]),
        },
    },
    {
        name:      'policy',
        path:      '/policy',
        component: Policy,
        meta:      {
            title:       (route, { i18n }) => i18n.t('page title policy'),
            breadcrumbs: (route, { i18n }) => ([
                {
                    label: i18n.t('header breadcrumb policy'),
                },
            ]),
        },
    },
    {
        name:      'assisted',
        path:      '/assisted/',
        component: Assisted,
        redirect:  { name: 'root' },
        children:  [
            {
                name:      'assistedThankYou',
                path:      'thank-you/',
                component: AssistedThankYou,
                meta:      {
                    title: (route, { i18n }) => i18n.t('page title assisted thank you'),
                },
            },
        ],
    },
    {
        name:      'sugar',
        path:      '/sugar',
        component: Sugar,
        meta:      {
            title:  'Sugar',
            layout: 'content',
        },
    },
    {
        name:      'suite',
        path:      '/suite',
        component: Suite,
        meta:      {
            title:  'Suite',
            layout: 'content',
        },
    },
    {
        name:      'notFound',
        path:      '*',
        component: NotFound,
        meta:      {
            title: '404 Not Found',
        },
    },
];

function processReturnUrl(config) {
    if (!config.user?.returnUrl) return removeReturnUrl();

    setReturnUrl(config.user.returnUrl);
}

const createRouter = ({ store, i18n, config }) => {
    processReturnUrl(config);

    const router = new VueRouter({
        mode:   'history',
        routes: getRoutes({ store }),
    });

    router.beforeEach((to, from, next) => {
        // Defaulting `sidebar` as base layout after `blank` src/layouts/layout.vue:22
        to.meta.layout ||= 'sidebar';

        let breadcrumbs = to.meta.breadcrumbs ?? [];

        if (typeof breadcrumbs === 'function') {
            try {
                breadcrumbs = breadcrumbs(to, { store, i18n });
            } catch {
                breadcrumbs = [];
            }
        }

        store.dispatch('breadcrumbs/SET', breadcrumbs);

        // No guard, empty accesses or has access
        if (!to.meta.acl || !store.getters['aclAccess/isExists'] || store.getters['aclAccess/isGranted'](to.meta.acl)) {
            return next();
        }

        // Not logged user
        if (!store.getters['user/isExists']) {
            // Not first navigation
            if (from.name !== null || to.name !== 'migrationList') {
                setReturnUrl(to.fullPath);
            }

            return next({ name: 'userRegister' });
        }

        next({ name: 'migrationList' });
    });

    router.afterEach((to) => {
        // Use next tick to handle router history correctly https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
        Vue.nextTick(() => {
            let title = i18n.t('title');

            if (to.meta.title) {
                title = typeof to.meta.title === 'function' ? to.meta.title?.(to, { i18n }) : to.meta.title;
            }

            document.title = title;
        });
    });

    return router;
};

export default createRouter;
