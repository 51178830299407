import { isString, isNumber } from 'lodash';

export {
    isNumber,
    isString,
    isArray,
    isPlainObject as isObject,
} from 'lodash';

export const isStringNumber = (value) => {
    if (!isString(value)) return false;

    return !Number.isNaN(Number(value));
};

export const toNumber = (value) => {
    if (isNumber(value)) return value;
    if (isStringNumber(value)) return Number(value);

    return 0;
};
