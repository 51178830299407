<script>
export default {
    name: 'AppSurveyRegistration',
};
</script>

<script setup>
import { computed, ref } from 'vue';
import { useStore } from '@/store/index.js';
import i18n from '@/plugins/i18n.js';
import { saveSurveyRegistration } from '@/components/user/api.js';
import { LEAD_SOURCE, SECTIONS } from '@/components/survey/registration/constants.js';
import SurveyRegistration from '@/components/survey/registration/components/index.vue';
import notify from '@/components/base-components/components/notification/notify.js';

const vuex = useStore();

const userIsExist = computed(() => vuex.getters['user/isExists']);
const active = computed(() => userIsExist.value && !vuex.getters['user/getRegistrationSurveyId']);

const loading = ref(false);

async function submit(answers) {
    if (!active.value) return;

    loading.value = true;

    try {
        await saveSurveyRegistration(answers || { [SECTIONS.LEAD_SOURCE]: LEAD_SOURCE.NO_ANSWER });

        if (answers) {
            notify.success({
                title:   i18n.t('survey success title'),
                message: i18n.t('survey thanks'),
            });
        }
    } catch ({ message }) {
        notify.error({
            title: i18n.t('tmp An error occurred'),
            message,
        });
    } finally {
        loading.value = false;
    }
}
</script>

<template>
    <base-modal
        v-if="userIsExist"
        overlay-class="bg-black/50 backdrop-blur-sm"
        :value="active"
        :close-on-press-escape="false"
        :close-on-click-overlay="false"
        size="large"
        @close="submit()"
    >
        <div v-loader="loading" class="flex flex-col items-center gap-y-5">
            <h2>Welcome & Insights</h2>

            <div class="flex flex-col items-center">
                <p class="mx-12 text-center font-medium">
                    Here’s a short intro to know before you get started, and we'd love to ask one quick question
                </p>

                <div class="mx-12 text-sm">
                    <p>
                        This is a robust platform for migrating data, offering features like filtering, merging, reformatting,
                        object transformation (e.g., Leads → Companies, Meetings → Notes), and bulk property creation for HubSpot,
                        monday.com,
                        Salesforce, or Pipedrive.
                    </p>

                    <p>
                        Don’t see your source system? We likely support it via a custom migration — just reach out with
                        your use case. Whether you prefer guided support or want us to handle everything for an additional fee, we’re here
                        to make
                        migration stress-free.
                    </p>
                </div>
            </div>

            <survey-registration
                class="mt-5 w-full"
                :active="active"
                :loading="loading"
                @submit="submit"
            />
        </div>
    </base-modal>
</template>
