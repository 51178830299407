import qs from 'qs';
import { instance } from '@/api/api';
import { getUrl as ApiUrl } from '@/api/utils';

const baseRoute = '/replicator';

export function getUrl(id, action, queryParams = {}) {
    const replicatorUrl = id ? `${baseRoute}/${id}` : baseRoute;

    const url = action ? `${replicatorUrl}/${action}` : replicatorUrl;

    return Object.keys(queryParams).length > 0 ? ApiUrl(url, queryParams) : url;
}

function GET(id, action, config) {
    return instance.get(getUrl(id, action), config);
}

function POST(id, action, data, config) {
    return instance.post(getUrl(id, action), qs.stringify(data), config);
}

export function replicatorGet(id) {
    return GET(id);
}

export function replicatorSummary(id) {
    return GET(id, 'summary');
}

export function replicatorConnect(id, data) {
    return POST(id, 'connect', data);
}

export function replicatorEntityList(replicatorId) {
    return GET(replicatorId, 'entity/list');
}

export function replicatorEntityRefresh(replicatorId) {
    return GET(replicatorId, 'entity/refresh');
}

export function replicatorEntityGet(replicatorId, entityId) {
    return GET(replicatorId, `entity/${entityId}`);
}

export function replicatorEntityFields(replicatorId, entityId) {
    return GET(replicatorId, `entity/${entityId}/field`);
}

export function replicatorEntitySelect(replicatorId, entityId, value) {
    return POST(replicatorId, `entity/${entityId}/select`, { value });
}

export function replicatorInstanceGet(replicatorId, instanceId) {
    return GET(replicatorId, `instance/${instanceId}`);
}

export function replicatorInstanceCreate(replicatorId) {
    return GET(replicatorId, 'instance/create');
}

export function replicatorInstanceStop(replicatorId, instanceId) {
    return GET(replicatorId, `instance/${instanceId}/stop`);
}

export function replicatorInstancePause(replicatorId, instanceId, reason = null) {
    const action = `instance/${instanceId}/pause`;

    if (!reason) return GET(replicatorId, action);

    return POST(replicatorId, action, { reason });
}

export function replicatorInstanceResume(replicatorId, instanceId) {
    return GET(replicatorId, `instance/${instanceId}/resume`);
}
