const prefix = 'fac';
const iconName = 'unite';
const width = 512;
const height = 262;
const ligatures = [];
const unicode = null;
const svgPathData = 'M26 0C11.6406 0 0 11.6406 0 26C0 40.3594 11.6406 52 26 52H159.224L200.324 130.918L159.138 210H26C11.6406 210 0 221.641 0 236C0 250.359 11.6406 262 26 262H174C176.704 262 179.312 261.587 181.764 260.821C188.57 259.005 194.62 254.447 198.13 247.707L245.37 157H266.259L313.499 247.707C317.348 255.096 324.249 259.864 331.852 261.269C333.823 261.747 335.882 262 338 262H486C500.359 262 512 250.359 512 236C512 221.641 500.359 210 486 210H352.491L311.305 130.918L352.406 52H486C500.359 52 512 40.3594 512 26C512 11.6406 500.359 0 486 0H338C336.92 0 335.856 0.0658264 334.81 0.193703C326.071 0.791222 317.842 5.79097 313.499 14.1289L266.174 105H245.455L198.13 14.1289C193.966 6.13387 186.229 1.20811 177.894 0.289696C176.624 0.0988922 175.324 0 174 0H26Z';

export const definition = {
    prefix,
    iconName,
    icon: [
        width,
        height,
        ligatures,
        unicode,
        svgPathData,
    ],
};

export { definition as faUnite };
