const prefix = 'fac';
const iconName = 'multiply';
const width = 512;
const height = 262;
const ligatures = [];
const unicode = null;
const svgPathData = 'M0 26C0 11.6406 11.6406 0 26 0H210C224.359 0 236 11.6406 236 26C236 40.3594 224.359 52 210 52H26C11.6406 52 0 40.3594 0 26ZM0 236C0 221.641 11.6406 210 26 210H210C224.359 210 236 221.641 236 236C236 250.359 224.359 262 210 262H26C11.6406 262 0 250.359 0 236ZM462.517 63.3253C469.696 50.8896 465.436 34.9883 453 27.8086C440.564 20.6289 424.663 24.8896 417.483 37.3252L393.703 78.5139L370.033 37.5167C362.854 25.0811 346.952 20.8203 334.517 28C322.081 35.1797 317.82 51.0811 325 63.5167L348.373 104H302C287.641 104 276 115.641 276 130C276 144.359 287.641 156 302 156H348.966L325.483 196.674C318.304 209.11 322.564 225.011 335 232.191C347.436 239.37 363.337 235.11 370.517 222.674L393.703 182.514L417 222.865C424.18 235.301 440.081 239.562 452.517 232.382C464.952 225.202 469.213 209.301 462.033 196.865L438.44 156H486C500.359 156 512 144.359 512 130C512 115.641 500.359 104 486 104H439.033L462.517 63.3253Z';

export const definition = {
    prefix,
    iconName,
    icon: [
        width,
        height,
        ligatures,
        unicode,
        svgPathData,
    ],
};

export { definition as faMultiply };
