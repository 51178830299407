import { STEP_COMPLETE, STEP_CONNECT, STEP_RUN, STEP_SETUP } from '@/modules/replicator/store/step';
import { REPLICATOR } from '@/store/modules/acl-access/constants';

const breadcrumbLink = i18n => ({
    component: () => import('@/components/app/header/breadcrumbs/link'),
    attrs:     {
        label: i18n.t('replicator.label'),
        to:    {
            name: 'replicatorList',
        },
        icon:  {
            name:   'clone',
            prefix: 'fas',
        },
    },
});

export const ROUTE_NAME_LIST = 'replicatorList';
export const ROUTE_NAME_CONNECT = 'replicatorConnect';
export const ROUTE_NAME_REPLICATOR = 'replicator';
export const ROUTE_NAME_SETUP = 'replicatorSetup';
export const ROUTE_NAME_RUN = 'replicatorRun';
export const ROUTE_NAME_COMPLETE = 'replicatorComplete';

export default [
    {
        name:      ROUTE_NAME_LIST,
        path:      '/replicator/list/',
        component: () => import('@/modules/replicator/pages/list'),
        meta:      {
            acl:         REPLICATOR.LIST,
            section:     'replicator',
            title:       (route, { i18n }) => i18n.t('replicator.label'),
            breadcrumbs: (route, { i18n }) => ([
                {
                    label: i18n.t('replicator.label'),
                },
                {
                    label: i18n.t('header breadcrumb list'),
                },
            ]),
        },
    },
    {
        name:      ROUTE_NAME_CONNECT,
        path:      '/replicator/:replicatorId?/connect/',
        component: () => import('@/modules/replicator/pages/connect'),
        meta:      {
            acl:         REPLICATOR.CONNECT,
            section:     'replicator',
            title:       (route, { i18n }) => i18n.t('replicator.label'),
            breadcrumbs: (route, { i18n }) => ([
                breadcrumbLink(i18n),
                {
                    label: i18n.t('header breadcrumb connect'),
                },
            ]),
            stepName:    STEP_CONNECT,
        },
    },
    {
        name:      ROUTE_NAME_REPLICATOR,
        path:      '/replicator/:replicatorId/',
        component: () => import('@/modules/replicator/pages/replicator'),
        meta:      {
            acl:     REPLICATOR.GET,
            section: 'replicator',
        },
        children:  [
            {
                name:      ROUTE_NAME_SETUP,
                path:      'setup/',
                component: () => import('@/modules/replicator/pages/nested/setup'),
                meta:      {
                    title:       (route, { i18n }) => i18n.t('replicator.label'),
                    label:       () => 'Setup',
                    breadcrumbs: (route, { i18n }) => ([
                        breadcrumbLink(i18n),
                        {
                            label: 'Setup',
                        },
                    ]),
                    stepName:    STEP_SETUP,
                },
            },
            {
                name:      ROUTE_NAME_RUN,
                path:      'run/',
                component: () => import('@/modules/replicator/pages/nested/run'),
                meta:      {
                    title:       (route, { i18n }) => i18n.t('replicator.label'),
                    label:       () => 'Run',
                    breadcrumbs: (route, { i18n }) => ([
                        breadcrumbLink(i18n),
                        {
                            label: 'Run',
                        },
                    ]),
                    stepName:    STEP_RUN,
                },
            },
            {
                name:      ROUTE_NAME_COMPLETE,
                path:      'complete/',
                component: () => import('@/modules/replicator/pages/nested/complete'),
                meta:      {
                    title:       (route, { i18n }) => i18n.t('replicator.label'),
                    label:       () => 'Complete',
                    breadcrumbs: (route, { i18n }) => ([
                        breadcrumbLink(i18n),
                        {
                            label: 'Complete',
                        },
                    ]),
                    stepName:    STEP_COMPLETE,
                },
            },
        ],
    },
];
