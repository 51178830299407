function getError(action, options, xhr) {
    let message;

    if (xhr.response) {
        message = `${xhr.response.error || xhr.response}`;
    } else if (xhr.responseText) {
        message = `${xhr.responseText}`;
    } else {
        message = `fail to ${options.method} ${action} ${xhr.status}`;
    }

    return {
        status: xhr.status,
        message,
    };
}

function getResponse(xhr) {
    const text = xhr.responseText || xhr.response;

    if (!text) return text;

    try {
        return JSON.parse(text);
    } catch {
        return text;
    }
}

export default function uploadFile(options) {
    const method = options.method || 'POST';

    const xhr = new XMLHttpRequest();

    xhr.upload.onprogress = (event) => {
        if (!event.lengthComputable) return;

        options.onProgress?.({
            ...event,
            percent: Math.round((event.loaded / event.total) * 100),
        });
    };

    xhr.onload = () => {
        if (xhr.status < 200 || xhr.status >= 300) {
            return options.onError(getError(method, options, xhr));
        }

        options.onSuccess(getResponse(xhr));
    };

    xhr.onerror = () => {
        options.onError(getError(method, options, xhr));
    };

    const formData = new FormData();

    if (options.data) {
        for (const [key, value] of Object.entries(options.data)) {
            if (Array.isArray(value) && value.length) {
                formData.append(key, ...value);
            } else {
                formData.append(key, value);
            }
        }
    }

    formData.append('file', options.file, options.file.name);

    xhr.open(method, options.action, true);

    xhr.send(formData);

    return xhr;
}
