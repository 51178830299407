import { computed, ref } from 'vue';

export const TRANSITIONS = {
    FADE:        'fade',
    SLIDE_LEFT:  'slide-left',
    SLIDE_RIGHT: 'slide-right',
};

export default function useTransition() {
    const name = ref(undefined);

    function change(newName) {
        name.value = newName;
    }

    const enterClass = computed(() => {
        if (name.value === TRANSITIONS.SLIDE_LEFT) return 'translate-x-full';
        if (name.value === TRANSITIONS.SLIDE_RIGHT) return '-translate-x-full';
        if (name.value === TRANSITIONS.FADE) return 'opacity-0';

        return '';
    });

    const leaveToClass = computed(() => {
        if (name.value === TRANSITIONS.SLIDE_LEFT) return '-translate-x-full';
        if (name.value === TRANSITIONS.SLIDE_RIGHT) return 'translate-x-full';
        if (name.value === TRANSITIONS.FADE) return 'opacity-0';

        return '';
    });

    const activeClass = computed(() => {
        if (name.value === TRANSITIONS.SLIDE_LEFT) return 'overflow-hidden duration-700';
        if (name.value === TRANSITIONS.SLIDE_RIGHT) return 'overflow-hidden duration-700';
        if (name.value === TRANSITIONS.FADE) return 'duration-300';

        return '';
    });

    const transitionMode = computed(() => (name.value ? '' : 'out-in'));

    return {
        activeClass,
        enterClass,
        leaveToClass,

        transitionMode,

        name,
        change,
    };
}
