<script>
export default {
    name: 'SurveyRegistrationLeadSource',
};
</script>

<script setup>
import { computed } from 'vue';
import i18n from '@/plugins/i18n.js';
import { LEAD_SOURCE } from '@/components/survey/registration/constants.js';

const props = defineProps({
    value: {
        type: Array,
    },
});

const emit = defineEmits({
    input: null,
});

const model = computed({
    get: () => props.value ?? [],
    set: value => emit('input', value),
});

function change(answer, value) {
    if (value) {
        model.value = [...model.value, answer];
        return;
    }

    const index = model.value.indexOf(answer);

    if (index === -1) return;

    model.value = [...model.value.toSpliced(index, 1)];
}

const sources = [
    {
        label: i18n.t('survey-registration.lead-source.values.friend coworker'),
        value: LEAD_SOURCE.COWORKER,
    },
    {
        label: i18n.t('survey-registration.lead-source.values.referral partner'),
        value: LEAD_SOURCE.PARTNER,
    },
    {
        label: i18n.t('survey-registration.lead-source.values.social'),
        value: LEAD_SOURCE.SOCIAL,
    },
    {
        label: i18n.t('survey-registration.lead-source.values.referral crm vendor'),
        value: LEAD_SOURCE.CRM_VENDOR,
    },
    {
        label: i18n.t('survey-registration.lead-source.values.search engine'),
        value: LEAD_SOURCE.SEARCH_ENGINE,
    },
    {
        label: i18n.t('survey-registration.lead-source.values.software review site'),
        value: LEAD_SOURCE.SOFTWARE_REVIEW,
    },
    {
        label: i18n.t('survey-registration.lead-source.values.other'),
        value: LEAD_SOURCE.OTHER,
    },
];
</script>

<template>
    <div class="w-full">
        <h4 class="text-center mb-8">{{ $t('survey-registration.lead-source.question') }}</h4>
        <div class="grid grid-cols-1 md:grid-cols-2 md:mx-10 gap-4">
            <base-checkbox
                v-for="source in sources"
                :key="source.value"
                :label="source.label"
                :value="model.includes(source.value)"
                @input="v => change(source.value, v)"
            />
        </div>
    </div>
</template>
