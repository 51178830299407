export default {
    CONNECT:       'estimate.connect',
    CONTACT_US:    'estimate.contact-us',
    GET_MIGRATION: 'estimate.get-migration',
    LIST:          'estimate.list',
    OPTIONS:       'estimate.options',
    REPORT:        'estimate.report',
    TARGET:        'estimate.target',
    VIEW:          'estimate.view',

    ENTITY: {
        CHANGE: 'estimate-entity.change',
        LIST:   'estimate-entity.list',
        VIEW:   'estimate-entity.view',
    },

    INSTANCE: {
        CREATE:   'estimate-instance.create',
        LIST:     'estimate-instance.list',
        PROGRESS: 'estimate-instance.progress',
        STOP:     'estimate-instance.stop',
        VIEW:     'estimate-instance.view',
    },
};
