/**
 * Format bytes
 *
 * @param {Number} value
 * @param {Object} options
 *
 * @returns {String}
 */
const formatBytes = (value, options = {
    units:     ['bytes', 'KB', 'MB', 'GB', 'TB'],
    space:     true,
    precision: 2,
    base:      1024,
}) => {
    if (value === 0) return '0 bytes';

    const i = Math.floor(Math.log(value) / Math.log(options.base));

    const size = value / options.base ** i;

    return `${size % 1 === 0 ? size : size.toFixed(options.precision)} ${options.units[i]}`;
};

export default formatBytes;
