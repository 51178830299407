import { io } from 'socket.io-client';
import { throttle } from 'lodash';

let socket;

const subs = new Map();

function has(channel, handler = undefined) {
    if (!subs.has(channel)) return false;

    return handler ? subs.get(channel).has(handler) : true;
}

function on(channel, handler, timeout = 0) {
    let join = false;

    if (!subs.has(channel)) {
        subs.set(channel, new Map());

        join = true;
    }

    const listeners = subs.get(channel);

    if (listeners.has(handler)) return;

    listeners.set(handler, throttle((data, serverOffset, acknowledge) => {
        if (!socket.auth.serverOffset || serverOffset > socket.auth.serverOffset) {
            handler(data);

            socket.auth.serverOffset = serverOffset;
        }

        acknowledge?.();
    }, timeout, {
        trailing: timeout > 2000,
    }));

    socket.on(channel, listeners.get(handler));

    if (join) {
        socket.emit('join', channel);
    }
}

function off(channel, handler) {
    if (!subs.has(channel)) return;

    const listeners = subs.get(channel);

    if (handler) {
        if (listeners.has(handler)) {
            socket.off(channel, listeners.get(handler));

            listeners.delete(handler);
        }
    } else {
        socket.removeAllListeners(channel);

        listeners.clear();
    }

    if (listeners.size) return;

    subs.delete(channel);
    socket.emit('leave', channel);
}

function emit(channel, data, unique = false) {
    socket.timeout(5000).emit('message', channel, JSON.stringify(data), unique);
}

function clear(...channels) {
    const list = channels.length ? channels : subs.keys();

    [...list].forEach(channel => off(channel));
}

export default {
    install(Vue, { config: { websocket: { host, port } } }) {
        const notify = (message, error) => {
            Vue.prototype.$bugsnag?.notify(error ?? new Error(message), (event) => {
                event.context = message;
                event.groupingHash = 'websocket';
            });
        };

        socket = io(`wss://${host}:${port}`, {
            auth:            {
                serverOffset: undefined,
            },
            withCredentials: true,
            ackTimeout:      5000,
            retries:         3,
        });

        socket.io.on('reconnect_error', error => notify('reconnect_error', error));
        socket.io.on('reconnect_failed', () => notify('reconnect_failed'));

        socket.io.on('reconnect', () => {
            [...subs.keys()].forEach((channel) => {
                socket.emit('join', channel);
            });
        });

        Vue.prototype.$socket = {
            has,
            on,
            off,
            emit,
            clear,
        };
    },
};
