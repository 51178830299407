import { computed, ref, watch } from 'vue';
import { defineStore } from 'pinia';
import useWebSockets from '@/composables/useWebSockets';
import { replicatorConnect, replicatorGet } from '@/modules/replicator/api';

export const STORE_ID = 'replicator';

const {
    on:  onWebSocket,
    off: offWebSocket,
} = useWebSockets();

const replicatorChannel = id => `replicator-${id}-update`;

const subscribeReplicator = (id, handler) => onWebSocket(replicatorChannel(id), handler, 300);
const unsubscribeReplicator = id => offWebSocket(replicatorChannel(id));

const dataInitialValue = {
    id:       null,
    isLocked: false,
    source:   null,
    target:   null,
    instance: null,
};

export const useReplicatorStore = defineStore(STORE_ID, () => {
    const loading = ref(false);
    const data = ref(dataInitialValue);

    async function load(id) {
        loading.value = true;

        const response = await replicatorGet(id);
        data.value = { ...data.value, ...response };

        loading.value = false;
    }

    watch(() => data.value.id, (newId, oldId) => {
        if (oldId) unsubscribeReplicator(oldId);

        subscribeReplicator(newId, () => load(data.value.id));
    });

    async function connect(connectData) {
        loading.value = true;

        const response = await replicatorConnect(data.value.id, connectData);
        data.value = { ...data.value, ...response };

        loading.value = false;
    }

    function reset() {
        if (data.value.id) {
            unsubscribeReplicator(data.value.id);
        }

        loading.value = false;
        data.value = dataInitialValue;
    }

    return {
        loading,
        data,
        load,

        isSourceAuthorized: computed(() => !!data.value.source?.isAuthorized),
        isTargetAuthorized: computed(() => !!data.value.target?.isAuthorized),
        connect,

        instance: computed(() => data.value.instance),

        reset,
    };
});
