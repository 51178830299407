import { computed, ref, watch } from 'vue';
import { defineStore } from 'pinia';
import { useRoute, useRouter } from 'vue-router/composables';
import i18n from '@/plugins/i18n';
import { useReplicatorStore } from '@/modules/replicator/store/replicator';
import {
    ROUTE_NAME_COMPLETE,
    ROUTE_NAME_CONNECT,
    ROUTE_NAME_REPLICATOR,
    ROUTE_NAME_RUN,
    ROUTE_NAME_SETUP,
} from '@/modules/replicator/router';

export const STEP_CONNECT = 'connect';
export const STEP_CONNECT_SOURCE = 'connect-source';
export const STEP_CONNECT_TARGET = 'connect-target';
export const STEP_SETUP = 'setup';
export const STEP_RUN = 'run';
export const STEP_COMPLETE = 'complete';

const STEP_STATUS_ACTIVE = 'active';
const STEP_STATUS_DONE = 'done';
const STEP_STATUS_WAIT = 'wait';

export const STORE_ID = 'replicator-step';

export const useReplicatorStepStore = defineStore(STORE_ID, () => {
    const routeStep = ref(null);

    const route = useRoute();

    watch(() => route.meta?.stepName, () => {
        routeStep.value = route.meta?.stepName;
    }, { immediate: true });

    const replicatorStore = useReplicatorStore();

    const STEPS = [
        {
            name:        STEP_CONNECT_SOURCE,
            title:       i18n.t('replicator.step.source.title'),
            description: i18n.t('replicator.step.source.description'),
            route:       ROUTE_NAME_CONNECT,
        },
        {
            name:        STEP_CONNECT_TARGET,
            title:       i18n.t('replicator.step.target.title'),
            description: i18n.t('replicator.step.target.description'),
            route:       ROUTE_NAME_CONNECT,
        },
        {
            name:        STEP_SETUP,
            title:       i18n.t('replicator.step.setup.title'),
            description: i18n.t('replicator.step.setup.description'),
            route:       ROUTE_NAME_SETUP,
        },
        {
            name:        STEP_RUN,
            title:       i18n.t('replicator.step.run.title'),
            description: i18n.t('replicator.step.run.description'),
            route:       ROUTE_NAME_RUN,
        },
        {
            name:        STEP_COMPLETE,
            title:       i18n.t('replicator.step.complete.title'),
            description: i18n.t('replicator.step.complete.description'),
            route:       ROUTE_NAME_COMPLETE,
        },
    ];

    const router = useRouter();

    function routeUpdate(stepName) {
        const toStep = STEPS.find(step => step.name === stepName);

        router.push({ name: toStep.route, params: { replicatorId: replicatorStore.data.id } });
    }

    function defineStep() {
        if (replicatorStore.isSourceAuthorized === false) return routeUpdate(STEP_CONNECT_SOURCE);
        if (replicatorStore.isTargetAuthorized === false) return routeUpdate(STEP_CONNECT_TARGET);

        const instanceStatus = replicatorStore.instance?.status;

        if (instanceStatus === 'complete') return routeUpdate(STEP_COMPLETE);
        if (instanceStatus) return routeUpdate(STEP_RUN);

        return routeUpdate(STEP_SETUP);
    }

    watch(() => replicatorStore.data, () => {
        if (!replicatorStore.data.id) return;
        if (!!routeStep.value || route.name === ROUTE_NAME_REPLICATOR) defineStep();
    });

    const active = computed(() => {
        if (routeStep.value !== STEP_CONNECT) return routeStep.value;
        if (replicatorStore.isSourceAuthorized === true && replicatorStore.isTargetAuthorized === false) return STEP_CONNECT_TARGET;

        return STEP_CONNECT_SOURCE;
    });

    const steps = computed(() => {
        const activeIndex = STEPS.findIndex(step => step.name === active.value);

        return STEPS.map((step, index) => {
            let stepStatus = STEP_STATUS_DONE;

            if (index === activeIndex) {
                stepStatus = STEP_STATUS_ACTIVE;
            }

            if (index > activeIndex) {
                stepStatus = STEP_STATUS_WAIT;
            }

            return {
                ...step,
                status: stepStatus,
            };
        });
    });

    function reset() {
        routeStep.value = null;
    }

    return {
        steps,
        active,
        routeStep,

        reset,
    };
});
