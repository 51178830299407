<script>
export default {
    name: 'BaseUpload',
};
</script>

<script setup>
import { computed } from 'vue';
import { useFileDialog } from '@vueuse/core';
import notify from '@/components/base-components/components/notification/notify.js';
import BaseUploadList from '@/components/base-components/components/upload/components/base-upload-list.vue';
import BaseUploadDrop from '@/components/base-components/components/upload/components/base-upload-drop.vue';
import uploadFile from '@/components/base-components/components/upload/utils/upload-file.js';
import { STATUS } from '@/components/base-components/components/upload/index.js';
import formatBytes from '@/plugins/filters/format-bytes.js';

const props = defineProps({
    value:     {
        type: Array,
    },
    action:    {
        type:     String,
        required: true,
    },
    data:      {
        type: Object,
    },
    accept:    {
        type: String,
    },
    multiple:  {
        type: Boolean,
    },
    drop:      {
        type: Boolean,
    },
    dropClass: {
        type: [String, Array, Object],
    },
    maxSize:   {
        type:    Number,
        default: 0,
    },
    onBeforeUpload: {
        type: Function,
    },
    onSuccess: {
        type: Function,
    },
    onError: {
        type: Function,
    },
});

const emit = defineEmits({
    input: null,
});

const model = computed({
    get: () => props.value,
    set: value => emit('input', value),
});

function addFile(file) {
    if (props.maxSize > 0 && props.maxSize < file.size) {
        notify.error({
            title:    'Incorrect File Size',
            message:  `File "${file.name}" exceeds the size limit of ${formatBytes(props.maxSize)}`,
            duration: 0,
        });

        return;
    }

    if (props.onBeforeUpload?.(file) === false) return;

    const item = {
        name:     file.name,
        size:     file.size,
        file,
        progress: null,
        status:   null,
        response: null,
        xhr:      uploadFile({
            action: props.action,
            data:   props.data,
            file,
            onProgress(event) {
                item.progress = event.percent;
            },
            onSuccess(response) {
                item.status = STATUS.SUCCESS;
                item.response = response;

                props.onSuccess?.(response);
            },
            onError(error) {
                item.status = STATUS.ERROR;
                item.response = error;

                props.onError?.(error);
            },
        }),
    };

    if (props.multiple) return model.value.push(item);

    model.value = [item];
}

function addFiles(files) {
    if (!files) return;

    [...files].forEach(file => addFile(file));
}

function removeFile(index) {
    const item = model.value[index] ?? null;

    if (!item) return;

    if (item.xhr && !item.status) {
        item.xhr.abort();
    }

    model.value.splice(index, 1);
}

const { open, reset, onChange } = useFileDialog({
    accept:   props.accept,
    multiple: props.multiple,
});

onChange(addFiles);
</script>

<template>
    <div class="flex flex-col gap-y-2">
        <base-upload-drop
            v-if="drop"
            :class="dropClass"
            :accept="accept"
            :multiple="multiple"
            @click="reset(); open()"
            @onDrop="addFiles"
        />
        <base-button
            v-else
            type="primary"
            size="small"
            @click="reset(); open()"
        >
            Click to upload
        </base-button>

        <slot name="tip">
            <div
                v-if="maxSize > 0 || accept"
                class="flex items-center gap-x-1 text-sm"
            >
                <span v-if="accept">{{ accept }}</span>
                <span>files</span>
                <span v-if="maxSize > 0">with a size less than {{ formatBytes(maxSize) }}</span>
            </div>
        </slot>

        <base-upload-list
            v-if="model.length"
            class="w-full"
            :list="model"
            @remove="removeFile"
        />
    </div>
</template>
