<script>
export default {
    name: 'BaseUploadDrop',
};
</script>

<script setup>
import { ref } from 'vue';
import { useDropZone } from '@vueuse/core';

const props = defineProps({
    accept:   {
        type: String,
    },
    multiple: {
        type: Boolean,
    },
});

const emit = defineEmits({
    click:  null,
    onDrop: null,
});

const baseUploadDropRef = ref(null);

const { isOverDropZone } = useDropZone(baseUploadDropRef, {
    dataTypes: (types) => {
        if (!props.multiple && types.length > 1) return false;

        if (!props.accept) return true;

        const accept = props.accept.split(',').map(a => a.trim());

        return types.every(type => accept.some((rule) => {
            if (rule.endsWith('/*')) return type.startsWith(rule.slice(0, -1));

            return rule === type;
        }));
    },
    onDrop:    (files) => {
        emit('onDrop', files);
    },
});
</script>

<template>
    <div
        ref="baseUploadDropRef"
        class="flex cursor-pointer flex-col items-center justify-center gap-y-3 rounded-md border border-dashed p-6 text-sm text-gray-300 hover:border-primary-600 hover:text-primary-500"
        :class="{
            'border-primary-600 text-primary-500 bg-primary-600/5': isOverDropZone,
        }"
        @click="emit('click')"
    >
        <base-icon name="cloud-upload" size="3x" />
        Drop file here or click to upload
    </div>
</template>
