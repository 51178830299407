import qs from 'qs';
import { instance } from '@/api/api';

export const EXCEPTION_CODES = {
    CODE_AUTH_2FA_CONFIRMATION: 400365020,
    CODE_2FA_CODE_REQUIRED:     400400015,
};

function getUrl(action) {
    return `/user/${action}`;
}

function GET(action, config) {
    return instance.get(getUrl(action), config);
}

function POST(action, data, config) {
    return instance.post(getUrl(action), qs.stringify(data), config);
}

export function userLogin(email, password) {
    return POST('login', {
        email,
        password,
    });
}

export function userRegister(username, email, password, partnerRequest = false) {
    return POST('register', {
        username,
        email,
        password,
        partnerRequest,
    });
}

export function userPasswordForgot(email) {
    return POST('password/forgot', {
        email,
    });
}

export function userPasswordChange(password, passwordVerify, code = null) {
    return POST('password/change', {
        password,
        passwordVerify,
        code,
    });
}

export function userPasswordReset(hash, email, password, passwordVerify, code = null) {
    return POST(`password/reset/${hash}/${email}`, {
        password,
        passwordVerify,
        code,
    });
}

export function userPasswordExpired(hash, email, password, passwordVerify, code = null) {
    return POST(`password/expired/${hash}/${email}`, {
        password,
        passwordVerify,
        code,
    });
}

export function userLogout() {
    return POST('logout');
}

export function userTwoFactorStatus() {
    return GET('2fa/status');
}

export function userTwoFactorVerify(code) {
    return POST('2fa/verify', {
        code,
    });
}

export function userTwoFactorEnable(code) {
    return POST('2fa/enable', {
        code,
    });
}

export function userTwoFactorDisable(code) {
    return POST('2fa/disable', {
        code,
    });
}

export function saveSurveyRegistration(answers) {
    return POST('save-survey/registration', {
        answers,
    });
}
