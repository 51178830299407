<script>
export default {
    name: 'BaseUploadList',
};
</script>

<script setup>
import { STATUS } from '@/components/base-components/components/upload/index.js';
import formatBytes from '@/plugins/filters/format-bytes.js';

defineProps({
    list: {
        type: Array,
    },
});

const emit = defineEmits({
    remove: null,
});

function remove(index) {
    emit('remove', index);
}
</script>

<template>
    <div class="flex flex-col gap-y-1">
        <div
            v-for="(item, index) in list"
            :key="index"
            class="relative overflow-hidden rounded group hover:bg-gray-100"
        >
            <div class="relative z-10 flex items-center justify-between gap-x-1 py-1 pr-1 pl-2">
                <base-icon
                    v-if="item.status === STATUS.SUCCESS || !item.xhr"
                    class="text-green-600 w-3.5"
                    name="check-circle"
                    prefix="far"
                />
                <base-icon
                    v-else-if="item.status === STATUS.ERROR"
                    class="text-red-600 w-3.5"
                    name="times-circle"
                    prefix="far"
                />
                <base-icon
                    v-else
                    class="animate-spin text-gray-600 w-3.5"
                    name="spinner"
                />

                <div class="flex flex-1 items-center justify-between gap-x-2 text-sm leading-none">
                    {{ item.name }}

                    <div v-if="item.size" class="text-xs text-gray-400">
                        {{ formatBytes(item.size) }}
                    </div>
                </div>

                <svg
                    class="ml-2 cursor-pointer rounded-sm text-gray-600/50 transition-colors duration-150 h-3.5 w-3.5 hover:bg-gray-600/20 hover:text-white"
                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
                    @click="remove(index)"
                >
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                </svg>
            </div>

            <div
                v-if="item.progress && !item.status"
                class="absolute inset-0 z-0 h-full duration-75 bg-brand-sushi/30 transition-size"
                :style="{
                    width: `${item.progress}%`
                }"
            />
        </div>
    </div>
</template>
