const prefix = 'fac';
const iconName = 'merge';
const width = 512;
const height = 262;
const ligatures = [];
const unicode = null;
const svgPathData = 'M26 0C11.6406 0 0 11.6406 0 26C0 40.3594 11.6406 52 26 52H210C224.359 52 236 40.3594 236 26C236 11.6406 224.359 0 210 0H26ZM26 210C11.6406 210 0 221.641 0 236C0 250.359 11.6406 262 26 262H210C224.359 262 236 250.359 236 236C236 221.641 224.359 210 210 210H26ZM394 12C408.359 12 420 23.6406 420 38V104H486C500.359 104 512 115.641 512 130C512 144.359 500.359 156 486 156H420V222C420 236.359 408.359 248 394 248C379.641 248 368 236.359 368 222V156H302C287.641 156 276 144.359 276 130C276 115.641 287.641 104 302 104H368V38C368 23.6406 379.641 12 394 12Z';

export const definition = {
    prefix,
    iconName,
    icon: [
        width,
        height,
        ligatures,
        unicode,
        svgPathData,
    ],
};

export { definition as faMerge };
