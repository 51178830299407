import Bugsnag from '@bugsnag/js';
import BugsnagPluginVue from '@bugsnag/plugin-vue';

export default {
    install(Vue, { config }) {
        if (!config.bugsnag?.apiKey) return;

        const bugsnagClient = Bugsnag.start({
            apiKey:        config.bugsnag.apiKey,
            plugins:       [new BugsnagPluginVue()],
            releaseStage:  config.bugsnag.releaseStage,
            collectUserIp: false,
            onError:       (event) => {
                if (window.user) {
                    event.setUser(window.user.id);
                    event.addMetadata('user', {
                        aclRole: window.user.aclRole,
                    });
                }
            },
        });

        Bugsnag.getPlugin('vue').installVueErrorHandler(Vue);

        Vue.prototype.$bugsnag = {
            notify(error, onError) {
                bugsnagClient.notify(error, onError);
            },
        };
    },
};
