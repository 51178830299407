<script>
export default {
    name: 'BaseVirtualList',
};
</script>

<script setup>
import { useVirtualList } from '@vueuse/core';
import { computed, watch } from 'vue';

const props = defineProps({
    list:         {
        type:     Array,
        required: true,
    },
    type:         {
        type:      String,
        default:   'vertical',
        validator: type => ['vertical', 'horizontal'].includes(type),
    },
    itemSize:     {
        type:      Number,
        required:  true,
        default:   10,
        validator: value => value > 0,
    },
    itemClass:    {
        type:    [String, Array],
        default: '',
    },
    keyField:     {
        type:    [Number, String],
        default: 'id',
    },
    bufferSize:   {
        type:    Number,
        default: 5,
    },
    wrapperClass: {
        type:    [String, Array],
        default: '',
    },
});

const computedList = computed(() => props.list);

const { list, containerProps, wrapperProps, scrollTo } = useVirtualList(
    computedList,
    {
        [props.type === 'vertical' ? 'itemHeight' : 'itemWidth']: props.itemSize,
        overscan:                                                 props.bufferSize,
    },
);

watch(() => props.list, () => {
    scrollTo(0);
});

defineExpose({
    scrollTo,
});
</script>

<template>
    <div
        v-bind="containerProps"
        class="overflow-y-auto"
        @scroll="containerProps.onScroll"
    >
        <div
            v-bind="wrapperProps"
            :class="wrapperClass"
        >
            <div
                v-for="{ index, data } in list"
                :key="data[keyField]"
                :class="itemClass"
            >
                <slot name="item" :index="index" :item="data" />
            </div>
        </div>
    </div>
</template>
