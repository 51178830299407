const prefix = 'fac';
const iconName = 'combine';
const width = 512;
const height = 289;
const ligatures = [];
const unicode = null;
const svgPathData = 'M170.819 52.9998H26C11.6406 52.9998 0 41.3592 0 26.9998C0 12.6404 11.6406 0.999832 26 0.999832H189.338C191.774 0.793365 194.213 0.934089 196.583 1.40249C205.418 2.97379 212.71 9.00752 216.045 17.0903C216.254 17.5964 216.484 18.094 216.758 18.5681L339.983 232C341.769 235.094 345.07 237 348.643 237H486C500.359 237 512 248.641 512 263C512 277.359 500.359 289 486 289H328C325.834 289 323.731 288.735 321.719 288.236C315.048 286.62 309.025 282.38 305.323 275.967L179.479 57.9998C177.693 54.9058 174.392 52.9998 170.819 52.9998ZM0 262.916C0 277.27 11.6369 288.907 25.9917 288.907H191.939C206.294 288.907 217.93 277.27 217.93 262.916C217.93 248.561 206.294 236.924 191.939 236.924H25.9917C11.6369 236.924 0 248.561 0 262.916ZM301.904 26.9922C301.904 41.347 313.541 52.9839 327.895 52.9839H485.845C500.2 52.9839 511.837 41.347 511.837 26.9922C511.837 12.6374 500.2 1.00049 485.845 1.00049H327.895C313.541 1.00049 301.904 12.6374 301.904 26.9922Z';

export const definition = {
    prefix,
    iconName,
    icon: [
        width,
        height,
        ligatures,
        unicode,
        svgPathData,
    ],
};

export { definition as faCombine };
