/**
 * Format percent

 * @param {Number} percent
 * @param {Object} options
 *
 * @returns {String}
 */
const formatPercent = (percent, options = {
    showPrecision: true,
    showSign:      true,
}) => {
    let formatted = percent;

    if (!options.showPrecision) {
        formatted = formatted.toFixed(0);

        if (percent < 1 && percent !== 0) {
            formatted = '<1';
        } else if (percent > 99 && percent < 100) {
            formatted = '>99';
        }
    }

    return options.showSign ? `${formatted}%` : formatted;
};

export default formatPercent;
