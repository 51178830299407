import ESTIMATE from '@/modules/estimate/acl-access';

export const ROUTE_NAME_ESTIMATE_LIST = 'estimateList';
export const ROUTE_NAME_ESTIMATE_CONNECT = 'estimateConnect';
export const ROUTE_NAME_ESTIMATE = 'estimate';
export const ROUTE_NAME_ESTIMATE_INSIGHTS = 'estimateInsights';
export const ROUTE_NAME_ESTIMATE_INSTANCES = 'estimateInstances';
export const ROUTE_NAME_ESTIMATE_INSTANCE_INSIGHTS = 'estimateInstanceInsights';

export const ROUTE_NAME_ASSET_ESTIMATE_LIST = 'assetEstimateList';
export const ROUTE_NAME_ASSET_ESTIMATE_CONNECT = 'assetEstimateConnect';
export const ROUTE_NAME_ASSET_ESTIMATE = 'assetEstimate';
export const ROUTE_NAME_ASSET_ESTIMATE_INSIGHTS = 'assetEstimateInsights';
export const ROUTE_NAME_ASSET_ESTIMATE_INSTANCES = 'assetEstimateInstances';
export const ROUTE_NAME_ASSET_ESTIMATE_INSTANCE_INSIGHTS = 'assetEstimateInstanceInsights';

export const SECTION_ESTIMATE = 'estimate';
export const SECTION_ASSET_ESTIMATE = 'asset-estimate';

const breadcrumbLink = (i18n, { label, to }) => ({
    component: () => import('@/components/app/header/breadcrumbs/link'),
    attrs:     {
        label,
        to:   {
            name: to,
        },
        icon: {
            name:   'calculator',
            prefix: 'fas',
        },
    },
});

const breadcrumbCrm = (route, store) => ({
    component: () => import('@/components/app/header/breadcrumbs/crm'),
    attrs:     {
        crm:   () => store.state.estimate.data.source?.platform?.type,
        label: route.params.estimateId,
    },
});

const estimateRoutes = () => ([
    {
        name:      ROUTE_NAME_ESTIMATE_LIST,
        path:      '/estimate/list/',
        component: () => import('@/modules/estimate/pages/list'),
        meta:      {
            acl:         ESTIMATE.LIST,
            section:     SECTION_ESTIMATE,
            title:       (route, { i18n }) => i18n.t('page title estimate'),
            breadcrumbs: (route, { i18n }) => ([
                {
                    label: i18n.t('header breadcrumb estimate'),
                },
                {
                    label: i18n.t('header breadcrumb list'),
                },
            ]),
        },
    },
    {
        name:      ROUTE_NAME_ESTIMATE_CONNECT,
        path:      '/estimate/:platform?/connect/:estimateId?',
        component: () => import('@/modules/estimate/pages/connect'),
        meta:      {
            acl:         ESTIMATE.CONNECT,
            section:     SECTION_ESTIMATE,
            title:       (route, { i18n }) => i18n.t('page title estimate'),
            breadcrumbs: (route, { i18n }) => ([
                breadcrumbLink(i18n, { label: i18n.t('header breadcrumb estimate'), to: ROUTE_NAME_ESTIMATE_LIST }),
                {
                    label: i18n.t('header breadcrumb connect'),
                },
            ]),
        },
    },
    {
        name:      ROUTE_NAME_ESTIMATE,
        path:      '/estimate/:platform?/:estimateId/',
        component: () => import('@/modules/estimate/pages/estimate'),
        redirect:  { name: ROUTE_NAME_ESTIMATE_INSIGHTS },
        meta:      {
            acl:     ESTIMATE.VIEW,
            section: SECTION_ESTIMATE,
        },
        children:  [
            {
                name:      ROUTE_NAME_ESTIMATE_INSIGHTS,
                path:      'insights/',
                component: () => import('@/modules/estimate/pages/nested/insights'),
                meta:      {
                    acl:         ESTIMATE.VIEW,
                    title:       (route, { i18n }) => i18n.t('page title estimate'),
                    label:       (route, { i18n }) => i18n.t('estimate.insights.title'),
                    breadcrumbs: (route, { i18n, store }) => ([
                        breadcrumbLink(i18n, { label: i18n.t('header breadcrumb estimate'), to: ROUTE_NAME_ESTIMATE_LIST }),
                        breadcrumbCrm(route, store),
                        {
                            label: i18n.t('estimate.insights.title'),
                        },
                    ]),
                },
            },
            {
                name:      ROUTE_NAME_ESTIMATE_INSTANCES,
                path:      'runs/',
                component: () => import('@/modules/estimate/pages/nested/runs'),
                meta:      {
                    title:          (route, { i18n }) => i18n.t('estimate.runs.title'),
                    label:          (route, { i18n }) => i18n.t('estimate.runs.tooltip'),
                    breadcrumbs:    (route, { i18n, store }) => ([
                        breadcrumbLink(i18n, { label: i18n.t('header breadcrumb estimate'), to: ROUTE_NAME_ESTIMATE_LIST }),
                        breadcrumbCrm(route, store),
                        {
                            label: i18n.t('estimate.runs.title'),
                        },
                    ]),
                    transitionName: 'slide',
                },
            },
            {
                name:      ROUTE_NAME_ESTIMATE_INSTANCE_INSIGHTS,
                path:      'runs/:instanceId/insights/',
                component: () => import('@/modules/estimate/pages/nested/run-insights'),
                meta:      {
                    title:          (route, { i18n }) => i18n.t('estimate.runs.title'),
                    label:          (route, { i18n }) => i18n.t('estimate.insights.title'),
                    breadcrumbs:    (route, { i18n, store }) => ([
                        breadcrumbLink(i18n, { label: i18n.t('header breadcrumb estimate'), to: ROUTE_NAME_ESTIMATE_LIST }),
                        breadcrumbCrm(route, store),
                        {
                            component: () => import('@/components/app/header/breadcrumbs/link'),
                            attrs:     {
                                label: i18n.t('estimate.runs.title'),
                                to:    {
                                    name: ROUTE_NAME_ESTIMATE_INSTANCES,
                                },
                                icon:  {
                                    name:   'history',
                                    prefix: 'fas',
                                },
                            },
                        },
                        {
                            label: route.params.instanceId,
                        },
                        {
                            label: i18n.t('estimate.insights.title'),
                        },
                    ]),
                    transitionName: 'slide',
                },
            },
        ],
    },
]);

const assetsEstimateRoutes = () => ([
    {
        name:      ROUTE_NAME_ASSET_ESTIMATE_LIST,
        path:      '/asset-estimate/list/',
        component: () => import('@/modules/estimate/pages/list'),
        meta:      {
            acl:         ESTIMATE.LIST,
            section:     SECTION_ASSET_ESTIMATE,
            title:       (route, { i18n }) => i18n.t('estimate.asset.title.one'),
            breadcrumbs: (route, { i18n }) => ([
                {
                    label: i18n.t('estimate.asset.title.one'),
                },
                {
                    label: i18n.t('header breadcrumb list'),
                },
            ]),
        },
    },
    {
        name:      ROUTE_NAME_ASSET_ESTIMATE_CONNECT,
        path:      '/asset-estimate/connect/:estimateId?',
        component: () => import('@/modules/estimate/pages/connect'),
        meta:      {
            acl:         ESTIMATE.CONNECT,
            section:     SECTION_ASSET_ESTIMATE,
            title:       (route, { i18n }) => i18n.t('estimate.asset.title.one'),
            breadcrumbs: (route, { i18n }) => ([
                breadcrumbLink(i18n, { label: i18n.t('estimate.asset.title.one'), to: ROUTE_NAME_ASSET_ESTIMATE_LIST }),
                {
                    label: i18n.t('header breadcrumb connect'),
                },
            ]),
        },
    },
    {
        name:      ROUTE_NAME_ASSET_ESTIMATE,
        path:      '/asset-estimate/:estimateId/',
        component: () => import('@/modules/estimate/pages/estimate'),
        redirect:  { name: ROUTE_NAME_ASSET_ESTIMATE_INSIGHTS },
        meta:      {
            acl:     ESTIMATE.VIEW,
            section: SECTION_ASSET_ESTIMATE,
        },
        children:  [
            {
                name:      ROUTE_NAME_ASSET_ESTIMATE_INSIGHTS,
                path:      'insights/',
                component: () => import('@/modules/estimate/pages/nested/insights'),
                meta:      {
                    acl:         ESTIMATE.VIEW,
                    title:       (route, { i18n }) => i18n.t('estimate.asset.title.one'),
                    label:       (route, { i18n }) => i18n.t('estimate.insights.title'),
                    breadcrumbs: (route, { i18n, store }) => ([
                        breadcrumbLink(i18n, { label: i18n.t('estimate.asset.title.one'), to: ROUTE_NAME_ASSET_ESTIMATE_LIST }),
                        breadcrumbCrm(route, store),
                        {
                            label: i18n.t('estimate.insights.title'),
                        },
                    ]),
                },
            },
            {
                name:      ROUTE_NAME_ASSET_ESTIMATE_INSTANCES,
                path:      'runs/',
                component: () => import('@/modules/estimate/pages/nested/runs'),
                meta:      {
                    title:          (route, { i18n }) => i18n.t('estimate.runs.title'),
                    label:          (route, { i18n }) => i18n.t('estimate.runs.tooltip'),
                    breadcrumbs:    (route, { i18n, store }) => ([
                        breadcrumbLink(i18n, { label: i18n.t('estimate.asset.title.one'), to: ROUTE_NAME_ASSET_ESTIMATE_LIST }),
                        breadcrumbCrm(route, store),
                        {
                            label: i18n.t('estimate.runs.title'),
                        },
                    ]),
                    transitionName: 'slide',
                },
            },
            {
                name:      ROUTE_NAME_ASSET_ESTIMATE_INSTANCE_INSIGHTS,
                path:      'runs/:instanceId/insights/',
                component: () => import('@/modules/estimate/pages/nested/run-insights'),
                meta:      {
                    title:          (route, { i18n }) => i18n.t('estimate.runs.title'),
                    label:          (route, { i18n }) => i18n.t('estimate.insights.title'),
                    breadcrumbs:    (route, { i18n, store }) => ([
                        breadcrumbLink(i18n, { label: i18n.t('estimate.asset.title.one'), to: ROUTE_NAME_ASSET_ESTIMATE_LIST }),
                        breadcrumbCrm(route, store),
                        {
                            component: () => import('@/components/app/header/breadcrumbs/link'),
                            attrs:     {
                                label: i18n.t('estimate.runs.title'),
                                to:    {
                                    name: ROUTE_NAME_ASSET_ESTIMATE_INSTANCES,
                                },
                                icon:  {
                                    name:   'history',
                                    prefix: 'fas',
                                },
                            },
                        },
                        {
                            label: route.params.instanceId,
                        },
                        {
                            label: i18n.t('estimate.insights.title'),
                        },
                    ]),
                    transitionName: 'slide',
                },
            },
        ],
    },
]);

export default [
    ...estimateRoutes(),
    ...assetsEstimateRoutes(),
];
