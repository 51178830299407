export const addModule = 'addModule';
export const applyData = 'applyData';
export const applyStepData = 'applyStepData';
export const changeStep = 'changeStep';
export const changeStepRequest = 'changeStepRequest';
export const checkSelectedModules = 'checkSelectedModules';
export const credentialValidationFail = 'credentialValidationFail';
export const disableSubmit = 'disableSubmit';
export const editModule = 'editModule';
export const formValidation = 'formValidation';
export const getUniqueValues = 'getUniqueValues';
export const hideStepLoading = 'hideStepLoading';
export const hidePageMessage = 'hidePageMessage';
export const mappingChanged = 'mappingChanged';
export const nextStep = 'nextStep';
export const platformDataSet = 'platformDataSet';
export const resetMapping = 'resetMapping';
export const resizeMapping = 'resizeMapping';
export const runningCommand = 'runningCommand';
export const saveMapping = 'saveMapping';
export const selectModule = 'selectModule';
export const showPageMessage = 'showPageMessage';
export const showStepLoading = 'showStepLoading';
export const stepDataLoaded = 'stepDataLoaded';
export const loadStep = 'loadStep';
export const submitStep = 'submitStep';
export const unlockRequest = 'unlockRequest';
export const mappingShowSelectValue = 'mappingShowSelectValue';
export const mappingHideSelectValue = 'mappingHideSelectValue';
export const mappingSelectValue = 'mappingSelectValue';
export const mappingShowRuleOptions = 'mappingShowRuleOptions';
export const mappingHideRuleOptions = 'mappingHideRuleOptions';
export const mappingRuleOptions = 'mappingRuleOptions';
export const mappingShowDefaultValue = 'mappingShowDefaultValue';
export const mappingHideDefaultValue = 'mappingHideDefaultValue';
export const mappingDefaultValue = 'mappingDefaultValue';
export const mappingShowRuleFilters = 'mappingShowRuleFilters';
export const mappingHideRuleFilters = 'mappingHideRuleFilters';
export const mappingRuleFilters = 'mappingRuleFilters';
export const demoImportantRecordsCount = 'demoImportantRecordsCount';
export const backupInstanceCreated = 'backupInstanceCreated';
export const fullscreenMode = 'fullscreenMode';

export const MAPPING = {
    SAVE:     'mapping.save',
    CHANGE:   'mapping.change',
    IMPORT:   'mapping.import',
    MESSAGES: 'mapping.messages',
    NOTES:    {
        ADD:    'mapping.notes.add',
        VIEW:   'mapping.notes.view',
        LINK:   'mapping.notes.link',
        UPDATE: 'mapping.notes.update',
    },
};

export const APP = {
    UPDATE:      'app.update',
    MAINTENANCE: 'maintenance',
};

export const MIGRATION = {
    ENTITIES: {
        REFRESH: 'app.migration.entities.refresh',
    },
};

export const MANAGER = {
    MIGRATION: {
        EDIT_ENTITY_PRIORITY: 'migration.edit_entity_priority',
        ENTITY:               {
            EDIT_FILTER:            'migration.entity.edit_filter',
            EDIT_RELATION_PRIORITY: 'migration.entity.edit_relation_priority',
        },
        INSTANCE:             {
            SETTINGS: 'migration.instance.settings',
        },
    },
};

export const USER = {
    GUID:     'user.guid',
    REDIRECT: 'user.redirect',
};
