<script>
export default {
    name: 'SurveyRegistration',
};
</script>

<script setup>
import { computed, ref } from 'vue';
import animate from '@/assets/util/animate.js';
import { addUnit } from '@/components/base-components/utils/dom.js';
import { SECTIONS } from '@/components/survey/registration/constants.js';
import SurveyRegistrationLeadSource from '@/components/survey/registration/components/steps/lead-source.vue';
// import SurveyRegistrationCrmVendor from '@/components/survey/registration/components/steps/crm-vendor.vue';
// import SurveyRegistrationHsSubscription from '@/components/survey/registration/components/steps/hs-subscription.vue';
// import SurveyRegistrationMigrationAssistance from '@/components/survey/registration/components/steps/migration-assistance.vue';
// import SurveyRegistrationOnboarding from '@/components/survey/registration/components/steps/onboarding.vue';
import useTransition, { TRANSITIONS } from '@/composables/useTransition.js';

defineProps({
    loading: {
        type: Boolean,
    },
});

const emit = defineEmits({
    submit: null,
});

const answers = ref({
    [SECTIONS.LEAD_SOURCE]:          [],
    // [SECTIONS.CRM_VENDOR]:           undefined,
    // [SECTIONS.HS_SUBSCRIPTION]:      undefined,
    // [SECTIONS.ONBOARDING]:           undefined,
    // [SECTIONS.MIGRATION_ASSISTANCE]: undefined,
});

const steps = computed(() => [
    {
        key:       SECTIONS.LEAD_SOURCE,
        component: SurveyRegistrationLeadSource,
    },
    // {
    //     key:       SECTIONS.CRM_VENDOR,
    //     component: SurveyRegistrationCrmVendor,
    //     available: answers.value[SECTIONS.LEAD_SOURCE].includes(LEAD_SOURCE.CRM_VENDOR),
    // },
    // {
    //     key:       SECTIONS.HS_SUBSCRIPTION,
    //     component: SurveyRegistrationHsSubscription,
    //     available: answers.value[SECTIONS.CRM_VENDOR] === CRM_VENDOR.HUB_SPOT,
    // },
    // {
    //     key:       SECTIONS.ONBOARDING,
    //     component: SurveyRegistrationOnboarding,
    // },
    // {
    //     key:       SECTIONS.MIGRATION_ASSISTANCE,
    //     component: SurveyRegistrationMigrationAssistance,
    // },
].filter(step => step.available !== false));

const step = ref(0);

const nextDisabled = computed(() => {
    const currentStepModel = answers.value[currentStep.value.key];

    if (Array.isArray(currentStepModel)) return currentStepModel.length === 0;

    return currentStepModel === undefined;
});

const {
    activeClass,
    enterClass,
    leaveToClass,
    transitionMode,
    change,
} = useTransition();

function resetAnswer() {
    answers.value[currentStep.value.key] = Array.isArray(answers.value[currentStep.value.key]) ? [] : undefined;
}

function back() {
    if (!steps.value[step.value - 1]) return;

    resetAnswer();

    change(TRANSITIONS.SLIDE_RIGHT);
    step.value -= 1;
}

function next() {
    if (step.value === steps.value.length - 1) {
        return emit('submit', answers.value);
    }

    change(TRANSITIONS.SLIDE_LEFT);
    step.value += 1;
}

const currentStep = computed(() => steps.value[step.value]);

const container = ref(null);

function onAppear(el) {
    container.value.style.height = addUnit(el.offsetHeight);
}

function onEnter(el) {
    if (!container.value) return;

    const start = container.value.offsetHeight;
    const diff = el.offsetHeight - start;

    animate((progress) => {
        container.value.style.height = addUnit(start + (progress * diff));
    }, null, 150);
}
</script>

<template>
    <div class="flex flex-col gap-y-10">
        <div ref="container" class="relative overflow-hidden">
            <transition
                :enter-active-class="activeClass"
                :leave-active-class="activeClass"
                :enter-class="enterClass"
                :leave-to-class="leaveToClass"
                :mode="transitionMode"
                @appear="onAppear"
                @enter="onEnter"
            >
                <component
                    :is="currentStep.component"
                    v-model="answers[currentStep.key]"
                    class="absolute"
                />
            </transition>
        </div>

        <div class="flex items-center gap-x-3">
            <base-button
                v-if="step > 0"
                :disabled="loading"
                label="Back"
                round
                @click="back"
            />
            <div class="flex flex-1 justify-end">
                <base-button
                    :disabled="nextDisabled || loading"
                    label="Continue"
                    type="primary"
                    round
                    @click="next"
                />
            </div>
        </div>
    </div>
</template>
