export const SECTIONS = {
    LEAD_SOURCE:          'leadSource',
    CRM_VENDOR:           'crmVendor',
    HS_SUBSCRIPTION:      'hsSubscription',
    ONBOARDING:           'onboarding',
    MIGRATION_ASSISTANCE: 'migrationAssistance',
};

export const LEAD_SOURCE = {
    COWORKER:        'Friend / Coworker',
    PARTNER:         'Referral by partner',
    SOCIAL:          'Social (LinkedIn / Facebook / YouTube, etc.)',
    CRM_VENDOR:      'Referral by CRM vendor',
    SEARCH_ENGINE:   'Search engine (Google / Bing, etc.)',
    SOFTWARE_REVIEW: 'Software review site (G2 / Capterra, etc.)',
    OTHER:           'Other',
    NO_ANSWER:       'N/A',
};

export const CRM_VENDOR = {
    HUB_SPOT:    'HubSpot',
    MS_DYNAMICS: 'MS Dynamics',
    SALESFORCE:  'Salesforce',
    ZOHO_CRM:    'Zoho CRM',
    CAPSULE:     'Capsule',
    COPPER:      'Copper',
    MONDAY:      'Monday',
    OTHER:       'Other',
};

export const HS_SUBSCRIPTION = {
    FREE: 'Free',
    PAID: 'Paid',
};

export const ONBOARDING = {
    YES:                 'Yes, we do',
    NO:                  'No, we haven\'t found a partner yet',
    HANDLING_ONBOARDING: 'We\'re handling onboarding and training in-house',
    OPEN_TO_SUGGESTIONS: 'We\'re open to suggestions for a partner',
};

export const MIGRATION_ASSISTANCE = {
    MYSELF:         'Myself, using migration software',
    GUIDED_SUPPORT: 'Guided support, using migration software',
    DO_IT:          'Please do it for me',
    NOT_SURE:       'Not sure yet',
};
