import i18n from '@/plugins/i18n.js';

export const DIRECTION = {
    SOURCE: 'source',
    TARGET: 'target',
};

export const REQUIRED = {
    ONE:    'one',
    ONE_OF: 'oneOf',
};

export const MAPPING = {
    CUSTOM_RULES:     'c',
    GENERATED_ENUMS:  'g',
    FIELDS_USAGES:    'u',
    NOTES:            'n',
    MAPPING:          'm',
    SOURCE:           's',
    TARGET:           't',
    REQUIRED:         'r',
    OVERRIDDEN:       'o',
    FIELDS_MAX_ITEMS: 'fm',
    COMPLEX_LIMITS:   'cl',
    FIELDS_FILTERS:   'fs',
    ENTITIES_MAPPING: 'em',
    RECORD_TYPE:      'rt',
    VERSION:          '_ver',
};

export const RULE = {
    MAPPING:  'm',
    SOURCE:   's',
    TARGET:   't',
    IGNORE:   'i',
    OVERTURN: 'o',
};

export const BLOCK = {
    CHECKED:   'c',
    DEFAULT:   'd',
    FUNCTION:  'f',
    SEPARATOR: 's',
    VALUE:     'v',
    HASH:      'h',
    FILTERS:   'fs',
    OPTIONS:   'o',
    FORMAT:    'fr',
    PARENT:    'p',
};

export const FUNCTION = {
    BLOCK:      'b',
    CONSTANT:   'cs',
    COMBINE:    'cb',
    DEPENDENCY: 'd',
    UNITE:      'u',
    FIELD:      'f',
    JOIN:       'j',
    MULTIPLY:   'm',
    TECHNICAL:  't',
    VALUE:      'v',
};

export const FIELD = {
    ID:               'id',
    ACCESS:           'a',
    COMBINATIONS:     'cbs',
    COMPLEX:          'c',
    COMPLEX_POINTER:  'cp',
    CREATED_FIELD:    'cf',
    DEFAULT:          'd',
    ENTITY:           'e',
    ENUM:             'en',
    EXTERNAL_TYPE:    'et',
    EXTRA:            'ex',
    FAKE:             'fk',
    GENERATED:        'g',
    HTML_PRETTY:      'hp',
    IS_ID:            'i',
    KEY:              'k',
    KEY_FIELD:        'kf',
    LABEL:            'l',
    META_DATA:        'md',
    MAX_FORMAT:       'mxf',
    MAX_ITEMS:        'mi',
    MAX_LENGTH:       'mxl',
    MAX_PARENT_ITEMS: 'mpi',
    MAX_PROPERTIES:   'mxp',
    MAX_VALUE:        'mx',
    MIN_FORMAT:       'mnf',
    MIN_LENGTH:       'mnl',
    MIN_PROPERTIES:   'mnp',
    MIN_VALUE:        'mn',
    MULTI:            'm',
    NATIVE:           'n',
    ONE_OF:           'oo',
    PATH:             'p',
    POINTERS:         'ps',
    PRECISION:        'pcs',
    RECORD_TYPES:     'rts',
    REQUIRED:         'rq',
    RESTRICTED:       'rst',
    ROLE:             'r',
    STRING_WRAPPER:   'sw',
    SUBTYPE:          'st',
    TYPE:             't',
    UNIQUE:           'uq',
    UNIQUE_WITH:      'uw',
    UPDATABLE:        'u',
    VALIDATORS:       'v',
    VISIBLE:          'vsb',
    WEIGHT:           'w',
};

export const META_DATA = {
    OVERRIDDEN:     'o',
    USAGE:          'u',
    GENERATED_ENUM: 'gen',
};

export const TYPES = {
    STRING:   's',
    INTEGER:  'i',
    DECIMAL:  'dec',
    FLOAT:    'f',
    DATETIME: 'dt',
    DATE:     'd',
    TIME:     't',
    BOOLEAN:  'b',
};

export const TYPES_LIST = {
    [TYPES.STRING]:   'string',
    [TYPES.INTEGER]:  'integer',
    [TYPES.DECIMAL]:  'decimal',
    [TYPES.FLOAT]:    'float',
    [TYPES.DATETIME]: 'datetime',
    [TYPES.DATE]:     'date',
    [TYPES.TIME]:     'time',
    [TYPES.BOOLEAN]:  'boolean',
};

export const TYPES_DEFAULT = {
    [TYPES.STRING]:   '_',
    [TYPES.INTEGER]:  0,
    [TYPES.DECIMAL]:  0,
    [TYPES.FLOAT]:    0,
    [TYPES.DATETIME]: '2023-02-10T17:14:56+0000',
    [TYPES.DATE]:     '2023-02-10',
    [TYPES.TIME]:     '17:14:56',
    [TYPES.BOOLEAN]:  true,
};

export const ROLE = {
    UNIFIED:     'u',
    NOT_UNIFIED: 'nu',
    CUSTOM:      'c',
};

export const COMBINATION = {
    REQUIRED: 'rq',
    UNIQUE:   'uq',
    UNITE:    'ut',
};

export const DEPENDENCY = {
    CONDITION: 'c',
    OPTIONS:   'o',
    TYPES:     't',
};

export const DEPENDENCY_CONDITION = {
    EMPTY: 'em',
    EQUAL: 'eq',
};

export const OTHER = {
    NULL_POINTER:       1000,
    DEFAULT_SEPARATOR:  '; ',
    DELIMITER:          ' || ',
    MULTIPLY_DELIMITER: ' || ',
};

export const USAGE = {
    LABEL:   'l',
    VALUE:   'v',
    ITEMS:   'i',
    RECORDS: 'r',
    COUNT:   'c',
};

export const USAGE_LABELS = {
    __true__:  'True',
    __false__: 'False',
    true:      'True',
    false:     'False',
};

export const CONSTANT_FORMAT = {
    STRING:   TYPES.STRING,
    INTEGER:  TYPES.INTEGER,
    DECIMAL:  TYPES.DECIMAL,
    FLOAT:    TYPES.FLOAT,
    DATETIME: TYPES.DATETIME,
    DATE:     TYPES.DATE,
    TIME:     TYPES.TIME,
    BOOLEAN:  TYPES.BOOLEAN,
    ENUM:     'en',
};

export const CONSTANT_FORMATS = {
    string:   CONSTANT_FORMAT.STRING,
    integer:  CONSTANT_FORMAT.INTEGER,
    decimal:  CONSTANT_FORMAT.DECIMAL,
    float:    CONSTANT_FORMAT.FLOAT,
    datetime: CONSTANT_FORMAT.DATETIME,
    date:     CONSTANT_FORMAT.DATE,
    time:     CONSTANT_FORMAT.TIME,
    boolean:  CONSTANT_FORMAT.BOOLEAN,
    enum:     CONSTANT_FORMAT.ENUM,
};

export const FILTER = {
    FUNCTION: 'f',
    OPTIONS:  'o',
    TYPES:    't',
};

export const VISIBILITY = {
    VISIBLE:   true,
    INVISIBLE: false,
    UNKNOWN:   null,
};

export const ENUM_WEIGHT = {
    MIN: 0,
    MAX: 100,
};

export const ENUM_WEIGHT_THRESHOLDS = [
    ENUM_WEIGHT.MIN,
    ENUM_WEIGHT.MAX,
];

export const FIELD_OPTION_NOT_IN_LIST = 'nil';

export const FIELD_OPTIONS = {
    // eslint-disable-next-line camelcase
    not_in_list: FIELD_OPTION_NOT_IN_LIST,
};

export const MAPPING_STRATEGY = {
    NATIVE:  'native',
    OPEN_AI: 'openai',
};

export const QUICK_FILTER_MAPPED = 'mapped';
export const QUICK_FILTER_UNMAPPED = 'unmapped';
export const QUICK_FILTER_RELATION = 'relation';
export const QUICK_FILTER_NON_RELATION = 'non-relation';
export const QUICK_FILTER_DROP_DOWN = 'drop-down';
export const QUICK_FILTER_CUSTOM = 'custom';
export const QUICK_FILTER_NATIVE = 'native';
export const QUICK_FILTER_STRING = 'string';
export const QUICK_FILTER_DATETIME = 'datetime';
export const QUICK_FILTER_DATE = 'date';
export const QUICK_FILTER_TIME = 'time';
export const QUICK_FILTER_INTEGER = 'integer';
export const QUICK_FILTER_DECIMAL = 'decimal';
export const QUICK_FILTER_FLOAT = 'float';
export const QUICK_FILTER_BOOLEAN = 'boolean';
export const QUICK_FILTER_NOTES = 'notes';
export const QUICK_FILTER_USAGE = 'usage';

export const QUICK_FILTERS = [
    {
        name:    'kind',
        options: [
            {
                value: QUICK_FILTER_MAPPED,
                label: i18n.t('mapping mapped'),
            },
            {
                value: QUICK_FILTER_UNMAPPED,
                label: i18n.t('mapping unmapped'),
            },
        ],
    },
    {
        name:    'relations',
        options: [
            {
                value: QUICK_FILTER_RELATION,
                label: i18n.t('quick filter relation'),
            },
            {
                value: QUICK_FILTER_NON_RELATION,
                label: i18n.t('quick filter non relation'),
            },
        ],
    },
    {
        name:    'drop-down',
        options: [
            {
                value: QUICK_FILTER_DROP_DOWN,
                label: i18n.t('quick filter drop down'),
            },
        ],
    },
    {
        name:    'roles',
        options: [
            {
                value: QUICK_FILTER_CUSTOM,
                label: i18n.t('mapping custom'),
            },
            {
                value: QUICK_FILTER_NATIVE,
                label: i18n.t('mapping native'),
            },
        ],
    },
    {
        name:    'types',
        options: [
            {
                value: QUICK_FILTER_STRING,
                label: 'String',
            },
            {
                value: QUICK_FILTER_DATETIME,
                label: 'Datetime',
            },
            {
                value: QUICK_FILTER_DATE,
                label: 'Date',
            },
            {
                value: QUICK_FILTER_TIME,
                label: 'Time',
            },
            {
                value: QUICK_FILTER_INTEGER,
                label: 'Integer',
            },
            {
                value: QUICK_FILTER_DECIMAL,
                label: 'Decimal',
            },
            {
                value: QUICK_FILTER_FLOAT,
                label: 'Float',
            },
            {
                value: QUICK_FILTER_BOOLEAN,
                label: 'Boolean',
            },
        ],
    },
    {
        name:    'notes',
        options: [
            {
                value: QUICK_FILTER_NOTES,
                label: i18n.t('quick filter notes'),
            },
        ],
    },
    {
        name:    'usage',
        options: [
            {
                value: QUICK_FILTER_USAGE,
                label: 'Usage',
            },
        ],
    },
];
